<template>
    <div class="visitSurveyList">
        <div style="background: #fff">
        <div class="search-wrap flex" @click="searchClick">
            <div style="display:flex; align-items: center">
                <van-icon size="0.3rem" name="search"/>
                <span>{{value}}</span>
            </div>
            <van-icon size="0.3rem" name="close" v-show="conditions" @click.stop="clearClick"/>
        </div>
        </div>
        <div class="flex align_items_center justify_content_space_around div-bg">
            <van-dropdown-menu active-color="#3F7C53">
                <van-dropdown-item v-model="talentType" :options="talentTypeList" @change="switchClick"/>
            </van-dropdown-menu>
            <van-dropdown-menu active-color="#3F7C53">
                <van-dropdown-item v-model="talentTag" :options="talentTagList" @change="switchClick"/>
            </van-dropdown-menu>
        </div>
        <div class="width_full">
            <div class="flex justify_content_space_between">
                <div :class="{'btnBgSelect':plainFlag[0],'btnBgNormal':!plainFlag[0]}"
                     @click="siteClick('', 0)">
                    <div class="btnNumTitle"
                         :style="{'color': plainFlag[0] ? 'white': '#96A1A8'}">{{ALLCOUNT}}
                    </div>
                    <div class="btnInfoTitle"
                         :style="{'color': plainFlag[0] ? 'white': '#96A1A8'}">全部
                    </div>
                </div>
                <div :class="{'btnBgSelect':plainFlag[1],'btnBgNormal':!plainFlag[1]}"
                     @click="siteClick('0', 1)">
                    <div class="btnNumTitle"
                         :style="{'color': plainFlag[1] ? 'white': '#96A1A8'}">{{CONTACTED}}
                    </div>
                    <div class="btnInfoTitle"
                         :style="{'color': plainFlag[1] ? 'white': '#96A1A8'}">同意合作
                    </div>
                </div>
                <div :class="{'btnBgSelect':plainFlag[2],'btnBgNormal':!plainFlag[2]}"
                     @click="siteClick('1', 2)">
                    <div class="btnNumTitle"
                         :style="{'color': plainFlag[2] ? 'white': '#96A1A8'}">{{TAILED}}
                    </div>
                    <div class="btnInfoTitle"
                         :style="{'color': plainFlag[2] ? 'white': '#96A1A8'}">已挂车
                    </div>
                </div>
                <div :class="{'btnBgSelect':plainFlag[3],'btnBgNormal':!plainFlag[3]}"
                     @click="siteClick('2', 3)">
                    <div class="btnNumTitle"
                         :style="{'color': plainFlag[3] ? 'white': '#96A1A8'}">{{ISSEUED}}
                    </div>
                    <div class="btnInfoTitle"
                         :style="{'color': plainFlag[3] ? 'white': '#96A1A8'}">已开单
                    </div>
                </div>

            </div>
        </div>

        <div v-if="nosite" class="nodata-wrap">
            <img :src="nodata" alt="" class="nodata-img">
            <div>暂无达人</div>
        </div>
        <div v-else class="data-wrap">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <div class="visit-item" v-for="(item, index) in dataList" :key="index" @click="visitSubClick(item)">
                        <div class="title">{{item.nickName}}-{{item.statusName}} {{item.talentTypeName}}</div>
                        <div class="flex desc">
                            <div>{{item.marketerName}}</div>
                            <div>{{item.createTime}}</div>
                        </div>
                        <div class="flex desc" v-if="item.remark">
                            <div>{{item.remark}}</div>
                        </div>
                        <div class="flex desc">
                            <div>近30天发布视频数：{{item.releaseNum}}个</div>
                        </div>
                        <div class="flex desc" v-if="item.saleVolume">
                            <div>GMV：当月{{item.currMonthSale}}元, 上月{{item.lastMonthSale}}元</div>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
        <div class="nextBtn flex">
            <van-button class="publicBtnActive" style="margin-right: 0.24rem;" @click="newTalentClick">新建达人</van-button>
            <van-button class="publicBtnActive" @click="goodsManage">商品管理</van-button>
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import common from '@/utils/common'
    import {getTalentList} from "../../utils/api"
    import threeLevelMenuOrg from '@/components/threeLevelMenuOrg'
    import {uwStatisticAction} from "../../utils/util";

    export default {
        components: {
            threeLevelMenuOrg
        },
        data() {
            return {
                nodata: require('@/assets/img/nodata/nodata.png'),
                talentTypeList: [
                    {text: '全部', value: ''},
                    {text: 'C类', value: 0},
                    {text: 'B类', value: 1},
                    {text: 'A类', value: 2},
                    {text: 'A+类', value: 3}
                ],
                talentTagList: [
                    {text: '全部', value: ''},
                    {text: '休眠', value: 0},
                    {text: '失活', value: 1}
                ],
                nosite: false,
                dataList: [],
                loading: false,
                finished: false,
                pageIndex: 1,
                pageSize: 10,
                isLoading: false,
                conditions: '',
                value: '',
                talentStatus: '',
                talentType: '',
                talentTag: '',
                deptNo: '',
                ALLCOUNT: 0,
                CONTACTED: 0,
                TAILED: 0,
                ISSEUED: 0,
                noAuthorization: 0,
                partAuthorization: 0,
                allAuthorization: 0,
                plainFlag: [true, false, false, false],
            }
        },
        mounted() {
            if (this.$route.query.conditions) {
                this.value = this.$route.query.conditions
                this.conditions = this.$route.query.conditions
            } else {
                this.value = '输入达人昵称/抖音ID'
                this.conditions = ''
            }
            this.toGetTalentList()
        },
        destroyed() {},
        methods: {
            visitSubClick(item) {
                this.$router.push({name: 'talentInput', query: {id: item.id}})
            },
            newTalentClick() {
                this.$router.push({name: 'talentInput', query: {isSelf: 'y'}})
            },
            goodsManage() {
                this.$router.push({name: 'goodsManage'})
            },
            clearClick() {
                this.value = '输入达人昵称/抖音ID'
                this.conditions = ''
                this.toGetTalentList()
            },

            toGetTalentList() {
                getTalentList({
                    uniqueNo: common.getItem('uniqueNo'),
                    conditions: this.conditions,
                    status: this.talentStatus ? this.talentStatus : '',
                    talentTag: this.talentTag,
                    talentType: this.talentType ? this.talentType : '',
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                }).then(res => {
                    this.loading = false
                    if (res.code == 200) {
                        if (res.data && res.data.businessCode == 200) {
                            console.log(res.data)
                            if (res.data.ALL_COUNT != undefined) this.ALLCOUNT = res.data.ALL_COUNT
                            if (res.data.CONTACTED_COUNT != undefined) this.CONTACTED = res.data.CONTACTED_COUNT
                            if (res.data.TAILED_COUNT != undefined) this.TAILED = res.data.TAILED_COUNT
                            if (res.data.ISSEUED_COUNT != undefined) this.ISSEUED = res.data.ISSEUED_COUNT

                            this.finished = !res.data.pageModel.hasNextPage;
                            if (this.pageIndex === 1) {
                                this.dataList = res.data.pageModel.list;
                            } else {
                                this.dataList = this.dataList.concat(res.data.pageModel.list);
                            }
                            console.log(this.dataList)
                            if (!this.finished) {
                                this.pageIndex++;
                            }
                        }
                    }
                })
            },
            switchClick() {
                this.dataList = []
                this.pageIndex = 1
                this.toGetTalentList()
            },
            onRefresh() {
                this.isLoading = false
                this.dataList = []
                this.pageIndex = 1
                this.toGetTalentList()
            },
            onLoad() {
                this.toGetTalentList()
            },
            oneChange(val) {
                this.pageIndex = 1
                this.toGetTalentList(val)
                this.deptNo = val
            },
            twoChange(val) {
                this.pageIndex = 1
                this.toGetTalentList(val)
                this.deptNo = val
            },
            threeChange(val) {
                this.pageIndex = 1
                this.toGetTalentList(val)
                this.deptNo = val
            },
            searchClick() {
                this.$router.push({name: 'searchTalent'})
            },
            siteClick(status, index) {
                this.talentStatus = status
                // 选中按钮颜色变化
                for (let [i, obj] of this.plainFlag.entries()) {
                    this.plainFlag[i] = false
                }
                this.plainFlag[index] = true
                this.pageIndex = 1
                this.toGetTalentList()
            },
            authClick(status, index) {
                // 选中按钮颜色变化
                for (let [i, obj] of this.plainFlag.entries()) {
                    this.plainFlag[i] = false
                }
                this.plainFlag[index] = true
                this.pageIndex = 1
                this.toGetTalentList()
            },
        }
    }
</script>

<style lang="less" scoped>
    .visitSurveyList {
        min-height: 100%;
        background: #F7F8F9;

        .flex {
            display: flex;
        }

        .top-fixed {
            position: fixed;
            width: 100%;
            z-index: 10;
        }

        .data-wrap {
            padding: .24rem 0.3rem 2rem;

            .visit-item {
                background: #fff;
                margin-bottom: 0.24rem;
                box-shadow: 0 0.18rem .26rem 0 rgba(0, 0, 0, 0.05);
                border-radius: 0.16rem;
                padding: 0.4rem 0.32rem;

                .title {
                    color: #333;
                    font-size: 0.3rem;
                    font-weight: bold;
                    word-break: break-all;
                }

                .desc {
                    color: #999;
                    font-size: 0.26rem;
                    justify-content: space-between;
                    margin-top: 0.24rem;
                }
            }
        }

        .nodata-wrap {
            padding-top: 0.5rem;
            text-align: center;
            color: #616161;
            font-size: 0.3rem;
        }

        .nodata-img {
            width: 60%;
            margin-top: 1rem;
        }

        .nextBtn {
            width: 100%;
            padding: 12px;
            background: #fff;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .search-wrap {
            height: 0.6rem;
            line-height: 0.6rem;
            padding: 0 0.24rem;
            border-radius: 0.3rem;
            background: #F0F0F0;
            margin: 0.24rem 0.1rem 0;
            font-size: 0.26rem;
            color: #999999;
            align-items: center;

            span {
                margin-left: 0.1rem;
            }
        }

        .btnBgSelect {
            width: 2.2rem;
            height: 1.2rem;
            background: url("../../assets/img/site/mySite_selete.png");
            background-size: 100% 100%;
            box-shadow: 0px 1px 5px 5px rgba(0, 0, 0, 0.05);
        }

        .btnBgNormal {
            width: 2.2rem;
            height: 1.2rem;
            background: url("../../assets/img/site/mySite_normol.png");
            background-size: 100% 100%;
        }

        .btnNumTitle {
            font-size: 25px;
            font-weight: bold;
            width: 100%;
            text-align: center;
            margin-top: 0.1rem;
            height: 30px;
        }

        .btnInfoTitle {
            font-size: 12px;
            font-weight: bold;
            width: 100%;
            text-align: center;
        }

    }

    /deep/ .van-dropdown-menu__title::after {
        border-color: transparent transparent #3F7C53 #3F7C53 !important;
    }

    .icon-triangle {
        width: 0;
        height: 0;
        border-left: 0.08rem solid transparent;
        border-right: 0.08rem solid transparent;
        border-top: 0.1rem solid #3F7C53;
        margin-left: 0.1rem;
    }

    /deep/ .van-dropdown-menu__bar {
        box-shadow: none;
    }

    .div-bg {
        background: #ffffff;
    }
</style>